;(() => {
    class GSSaveSubscriptionRequest {
        constructor(email, userip, recaptchaToken) {
            this.email = email;
            this.userip = userip;
            this.recaptchaToken = recaptchaToken;
        }

        getData(action) {
            let formData = {};
            formData.action = action;
            formData.email_subscription = this.email;
            formData.userip = this.userip;
            formData.re_token = this.recaptchaToken;

            console.log(formData);
            return formData;
        }

        async sendRequest() {
            return request(this.getData("gs_save_subscription"));
        }

        async sendRequestNoCaptcha() {
            return request(this.getData("gs_save_subscription_subsform"));
        }
    }

    const request = async function (data) {
        return new Promise((resolve, reject) => {
            jQuery.post(lumina.ajax_url, data)
                .then(resolve)
                .fail(reject);
        });
    }

    window.GSSaveSubscriptionRequest = GSSaveSubscriptionRequest;
})();
