;(function(){

    window.GSApp = window.GSApp || {};

    const getUserAgent = () => navigator.userAgent.toLowerCase();

    function getMobileDevice() {
        var userAgent = getUserAgent();

        // Check for various mobile device user agents
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/ipad|iphone|ipod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return false;
    }

    GSApp.NavigatorChecker = {
        isSafari:  function() {
            return getUserAgent().includes('safari') && getUserAgent().includes('applewebkit') && getUserAgent().includes('chrome') === false;
        },

        isChrome: function() {
            return getUserAgent().includes('chrome') && getUserAgent().includes('edg') === false && getUserAgent().includes('opr') === false;
        },

        isMobileDevice: () => getMobileDevice() !== false,

        isIOS: () => getMobileDevice() === 'iOS',

        isMobileWindows: () => getMobileDevice() === 'Windows Phone',

        isAndroid: () => getMobileDevice() === 'Android'
    }
})();
