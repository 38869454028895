;(function(){
    window.GSApp = window.GSApp || {};

    const urlParse = function(url) {
        const parser = document.createElement('a');
        parser.href = url;

        return {
            protocol: parser.protocol,
            domain: parser.hostname,
            path: parser.pathname,
            query: parser.search
        };
    }

    GSApp.UrlChecker = class {
        constructor(url) {
            this.url = urlParse(url);
        }

        isDomain(domain) {
            return this.url.domain === domain;
        }

        isPath(path) {
            return this.url.path === path;
        }

        pathIncludes(path) {
            return this.url.path.indexOf(path) > -1;
        }

        hasQuery(query) {
            return this.url.query.indexOf(query) > -1;
        }

        isHttps() {
            return this.url.protocol === 'https';
        }
    }
})();
