;(function () {
    window.GSApp = window.GSApp || {};
    GSApp.System = GSApp.System || {};
    GSApp.Controllers = GSApp.Controllers || {};

    GSApp.isDebug = () => lumina.debug;

    const maxTimeOnLoad = 5000;

    // here is . as a value means that this is the library
    const requiredLibraries = {
        GSApp: {
            messages: '.',
            UrlChecker: '.',
            Utils: [
                'InputsController',
                'ElementUtil'
            ]
        },
        jQuery: '.',
        Lum: '.'
    };

    const init = async function () {
        try {
            console.log('gs_app waiting for libraries', requiredLibraries)
            await GSApp.System.waitingLibraries(requiredLibraries, maxTimeOnLoad);

            console.log('gs_app init');
            document.dispatchEvent(new CustomEvent('gs_app:init', {detail: GSApp}));

            console.log('gs_app waiting for document ready to interact');
            await waitDocumentInteractive();

            console.log('gs_app init and document ready to interact');
            document.dispatchEvent(new CustomEvent('gs_app:init_and_document_interactive', {detail: GSApp}));

            console.log('gs_app waiting for document completely ready');
            await waitDocumentReady();

            console.log('gs_app init and document completely ready');
            document.dispatchEvent(new CustomEvent('gs_app:init_and_document_ready', {detail: GSApp}));
        } catch (e) {
            console.error('gs_app init error: ', e);
        }
    }

    GSApp.System.waitingLibraries = function (requiredLibraries, maxTimeOnLoad) {
        return new Promise(function(resolve, reject) {
            const runCheck = async function (lumAppLoader) {
                try {
                    await lumAppLoader(requiredLibraries, maxTimeOnLoad);

                    resolve();
                } catch (err) {
                    let errMessage = GSApp.messages.app_loading_failed;

                    if (err instanceof Error) {

                        errMessage += + err.message;

                        if (err.libsNotLoaded) {
                            errMessage += "\nlibraries not loaded: " + err.libsNotLoaded.join(", ");
                        }
                    } else {
                        errMessage += err;
                    }

                    reject(errMessage);
                }
            }

            typeof lumAppLoader !== 'undefined' ? runCheck(lumAppLoader) : document.addEventListener('lumAppLoader:init', async function(e){ runCheck(e.detail); });
        });
    }

    const waitDocumentInteractive = async function () {
        return new Promise(function (resolve, reject) {
            const interval = setInterval(function () {
                if (['complete', 'interactive'].includes(document.readyState)) {
                    clearInterval(interval);
                    return resolve();
                }
            }, 5);
        });
    }

    const waitDocumentReady = async function () {
        return new Promise(function (resolve, reject) {
            const interval = setInterval(function () {
                if (document.readyState === 'complete') {
                    clearInterval(interval);
                    return resolve();
                }
            }, 5);
        });
    }

    init();
})();
