;document.addEventListener('DOMContentLoaded', function() {
    const initInterval = setInterval(function () {
        if (jQuery) {
            clearInterval(initInterval);
            init(jQuery);
        }
    }, 50);

    function init($) {
        const $addToCartBtn = $('.jsAddProductToCart');

        if ($addToCartBtn.length === 0) {
            return;
        }

        const $loader = $('.loader-wrapper');
        const showLoader = () => {
            if ($loader.length) {
                $loader.show();
            }
        }
        const hideLoader = () => {
            if ($loader.length) {
                $loader.hide();
            }
        }

        $addToCartBtn.on('click', async function () {
            const productSKU = $(this).data('productSku');

            showLoader();

            try {
                const response = await $.get(`/wp-admin/admin-ajax.php`, {
                    action: 'gs_add_product_to_cart',
                    product_sku: productSKU
                });

                if (typeof response.data?.redirect_url === 'undefined') {
                    const error = new Error('Something went wrong! Please try again later!');
                    error.response = response;
                }

                window.location.href = response.data.redirect_url;

            } catch (error) {

                let message = 'Server error.';

                if (error.responseText) {

                    let response = JSON.parse(error.responseText);
                    message = response.data.error;

                } else if (error.message) {

                    message = error.message;
                }

                console.error('Could not add product to a cart', productSKU, error);

                alert(message)
            }

            hideLoader();
        });
    }
});
