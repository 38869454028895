;(function () {
    window.GSApp = window.GSApp || {};
    GSApp.Utils = GSApp.Utils ? GSApp.Utils : {};

    function InputsController() {
        this.inputs = {};
    }

    InputsController.prototype.addInput = function (name, inputId) {
        const element = getElement(inputId);
        if (element && (element.nodeName === "INPUT" || element.nodeName === "TEXTAREA" || element.nodeName === "SELECT")) {
            this.inputs[name] = inputId;
        } else {
            console.error('The provided element is not a valid form control (input, textarea, or select).');
        }
    };

    InputsController.prototype.setValue = function (name, value) {
        var element = getElement(this.inputs[name]);
        if (element) {
            if (element.nodeName === "SELECT") {
                var options = Array.from(element.options);
                var selectedOption = options.find(option => option.value === value);
                if (selectedOption) {
                    element.value = value;
                } else {
                    console.warn('No option found with the value ' + value);
                }
            } else {
                element.value = value;
            }
        } else {
            console.warn('No element found with the name ' + name);
        }
    };

    InputsController.prototype.getValue = function (name) {
        var element = getElement(this.inputs[name]);
        if (element) {
            return element.value;
        } else {
            console.warn('No element found with the name ' + name);
            return null;
        }
    };

    InputsController.prototype.setValues = function (nameValuePairs) {
        for (var name in nameValuePairs) {
            if (nameValuePairs.hasOwnProperty(name)) {
                this.setValue(name, nameValuePairs[name]);
            }
        }
    };

    InputsController.prototype.getValues = function () {
        var values = {};

        for (let inputName in this.inputs) {
            if (this.inputs.hasOwnProperty(inputName)) {
                values[inputName] = this.getValue(inputName);
            }
        }

        return values;
    };

    InputsController.prototype.onChange = function (name, callback) {
        document.addEventListener('input', function(event) {
            if (event.target.id === this.inputs[name]) {
                callback(event);
            }

        });
    }

    InputsController.prototype.onChangeAny = function (callback) {
        document.addEventListener('input', function(event) {
            if (isIdInInputsList(event.target.id, this.inputs)) {
                callback(event);
            }
        }.bind(this));
    }

    function getElement(id) {
        return document.getElementById(id);
    }

    function isIdInInputsList(id, inputs) {
        return Object.values(inputs).includes(id);
    }

    GSApp.Utils.InputsController = InputsController;

    document.dispatchEvent(new CustomEvent('InputsController:init', {detail: InputsController}));
})();
