//Subscribe form
;(function ($) {
    let btnActionProcessing = false;

    $('.btn-subscribe').click(async function (e) {
        e.preventDefault();

        if (btnActionProcessing) {
            return false;
        }
        btnActionProcessing = true;

        const btn = $(this);
        const errSub = btn.parent().parent().find('.r-error.sub');
        const emailEl = btn.parent().find('input[type=email]');
        const loaderEl = $('.loader-wrapper');

        errSub.removeClass('sh');
        setErrorMessage(btn, "");
        loaderEl.show();

        let error = false;

        try {

            validate(emailEl);

            const recaptchaToken = await GSApp.getRecaptchaToken();

            const data = await sendSubscribtionRequest(getAjaxData(emailEl, recaptchaToken));

            if (data.success === false) {
                error = true;
                setErrorMessage(btn, data.data.message);
            }

        } catch (e) {
            error = true;

            switch (e.name) {
                case "ValidatorError":
                    console.warn("Validator exception.", e.message);
                    //parsley will provide all error messages
                    break;
                case "ReCaptchaError":
                    Lum.Recaptcha.reset();
                    errSub.addClass('sh');
                    console.warn("ReCaptcha exception.", e.message);
                    break
                case "RequestError":
                    setErrorMessage(btn, e.data.message);
                    break;
            }
        }

        loaderEl.hide();
        btnActionProcessing = false;
        emailEl.removeClass('parsley-success');

        if (error) {
            return;
        }

        setErrorMessage(btn, "");
        $('.thanks-modal').modal('show');
    });

    function setErrorMessage(button, message) {
        getErrorEl(button).textContent = message;
    }

    function getErrorEl(button) {
        let errorEl = document.getElementById('error-message-subscribe-footer');

        switch (button.attr('data-name')) {
            case 'home-page':
            case 'home-page-new':
                errorEl = document.getElementById('error-message-subscribe-home-page');
                break;
            case 'archive-blog':
                errorEl = document.getElementById('error-message-subscribe-archive-blog');
                break;
            case 'page-plan-information':
                errorEl = document.getElementById('error-message-subscribe-page-plan-information');
                break;
            case'search-page':
                errorEl = document.getElementById('error-message-subscribe-search-page');
                break;
            case 'category-page':
                errorEl = document.getElementById('error-message-subscribe-category');
                break;
        }

        return errorEl;
    }

    function validate(emailEl) {
        emailEl.parsley().validate();

        if (!emailEl.parsley().isValid()) {
            throw new ValidatorError();
        }
    }

    function getAjaxData(emailEl, recaptchaToken) {
        return {
            email_subscription: emailEl.val(),
            userip: $("#user_ip").val(),
            recaptchaToken: recaptchaToken
        };
    }

    async function sendSubscribtionRequest(ajaxData) {

            let data = {};
            try {
                const subscriptionRequest = new GSSaveSubscriptionRequest(ajaxData.email_subscription, ajaxData.userip, ajaxData.recaptchaToken);
                data = await subscriptionRequest.sendRequest();

                return data;

            } catch (e) {

                console.warn("newsletter subscription error. ", e);

                data = {
                    success: false,
                    message: window.GSApp.getStandardMessage("server_error")
                };
                if (ObjectUtils.checkObjectKey(e, "responseJSON.data.errors")) {
                    data = e.responseJSON.data;
                    data.message = data.errors.join(".<br />");
                }
                console.log("request error", data);

                throw new RequestError(window.GSApp.getStandardMessage("server_error"), data);
            }
    }

    class ValidatorError extends Error {
        constructor(message) {
            super('Please enter a valid email address.');
            this.name = "ValidatorError";
            this.code = 100;
        }
    }

    class RequestError extends Error {
        constructor(message, data) {
            super(message);
            this.name = "RequestError";
            this.code = 102;
            this.data = data;
        }
    }
})(jQuery);
