;(function () {
    'use strict';

    window.GSApp = window.GSApp || {};

    class ReCaptchaError extends Error {
        constructor(message) {
            super('Google Recaptcha didn\'t response.');
            this.name = "ReCaptchaError";
            this.code = 101;
        }
    }

    GSApp.getRecaptchaToken = async function () {

        let recaptchaToken = null;
        let error = false;

        await new Promise(function (resolve, reject) {

            const recaptchaTimeout = setTimeout(function () {
                error = true;
                console.warn('captcha rejected');
                reject();
            }, 25000); // 25 sec

            Lum.Recaptcha.onValidation(async function (token) {
                clearTimeout(recaptchaTimeout);
                recaptchaToken = token;
                resolve();
            });
        });

        Lum.Recaptcha.reset();

        if (error) {
            throw new ReCaptchaError();
        }

        return recaptchaToken;
    }
})();
