;(function () {
    const getTabs = (tabGroup) => Array.from((tabGroup ?? document).querySelectorAll('.jsLumTab'));

    const switchToTab = (tabEl, tabGroup) => {
        const tabContentID = tabEl.dataset.tab;
        const tabContentEl = document.getElementById(tabContentID);

        hideTabs(tabGroup);
        hideTabsContent(tabGroup);
        showTab(tabEl);
        showTabContent(tabContentEl);
    }

    const hideTabs = (tabGroup) => {
        getTabs(tabGroup).forEach(tab => tab.classList.remove('jsLumTab__active'));
    }

    const hideTabsContent = (tabGroup) => {
        Array.from(tabGroup.querySelectorAll('.lumTabContent'))
            .map(tabContentEl => tabContentEl.classList.remove('lumTabContent__active'));
    }

    const showTab = (tabEl) => {
        tabEl.classList.add('jsLumTab__active');
    }

    const showTabContent = (tabContentEl) => {
        if (tabContentEl) {
            tabContentEl.classList.add('lumTabContent__active');
        }
    }

    const getTabGroup = (tabEl) => {
        return tabEl.closest('.jsLumTabsGroup');
    }

    document.addEventListener('gs_app:init_and_document_interactive', function () {

        Array.from(getTabs()).map((tabEl) => {
            tabEl.addEventListener('click', function (e) {
                const tabEl = e.target;
                const tabGroup = getTabGroup(tabEl);

                switchToTab(tabEl, tabGroup);
            });
        });
    });
})();
