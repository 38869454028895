;(function(){
    window.GSApp = window.GSApp || {};

    GSApp.messages = {
        "server_error": "Server error. Please try again later.",
        'app_loading_failed': 'Some libraries are not loaded. Error: ',
        'app_loading_failed_checkout': 'Checkout: Some libraries are not loaded. Error: ',
    };

    GSApp.getStandardMessage = function(key) {
        return GSApp.messages[key];
    }
})();
