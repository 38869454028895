;(function(){
    window.GSApp = window.GSApp || {};

    GSApp.Utils = GSApp.Utils ? GSApp.Utils : {};

    GSApp.Utils.Cookies = {
        setCookie: function(name, value, args) {
            const defaultArgs = {
                path: '/',
                maxAge: 60 * 60 * 24,//1 day
                domain: location.host,
                secure: location.protocol === 'https',
                samesite: null,
                HttpOnly: false,
            };
            args = Object.assign(defaultArgs, args);

            const {path, maxAge, domain, secure, SameSite, HttpOnly} = args;

            let cookie = `${name}=${encodeURIComponent(value)}`;
            cookie += `;Max-Age=${parseInt(maxAge)}`;
            cookie += `;path=${path}`;
            cookie += `;domain=${domain}`;
            cookie += `${secure ? ';secure' : ''}`;
            cookie += `${SameSite ? ';SameSite' : ''}`;
            cookie += `${HttpOnly ? ';HttpOnly': ''}`;
            cookie += `;`;

            document.cookie = cookie;
        },

        getCookie: function(name) {
            var nameEQ = name + '=';
            var ca = document.cookie.split(';');

            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim(); // Trim spaces from the start

                if (c.indexOf(nameEQ) === 0) {
                    return decodeURIComponent(c.substring(nameEQ.length));
                }
            }

            return null;
        },

        isCookieSet: function(name) {
            return this.getCookie(name) !== null;
        }
    }

    //call event that library is loaded
    document.dispatchEvent(new CustomEvent('lum:utils:cookies', {detail: GSApp.Utils.Cookies}));
})();
