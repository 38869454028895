;(() => {
    class GSCreateNewUser {
        constructor(name, username, email, password, recaptchaToken) {
            this.name = name;
            this.username = username;
            this.email = email;
            this.password = password;
            this.recaptchaToken = recaptchaToken;
        }

        getData(action) {
            let formData = {};

            formData.action = action;
            formData.display_name = this.name;
            formData.username = this.username;
            formData.email = this.email;
            formData.password = this.password;
            formData.re_token = this.recaptchaToken;

            return formData;
        }

        async sendRequest() {
            return request(this.getData("gs_register_user"));
        }
    }

    const request = async function (data) {
        return new Promise((resolve, reject) => {
            jQuery.post(lumina.ajax_url, data)
                .then(resolve)
                .fail(reject);
        });
    }

    window.GSCreateNewUser = GSCreateNewUser;
})();
