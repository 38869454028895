;(function () {
    let reasonToNotShowPopup = '';

    let intervalCounter = 0;
    const initInterval = setInterval(() => {
        if (isRightPage() === false) {
            clearInterval(initInterval);
            return;
        }

        if (shouldInit()) {
            clearInterval(initInterval);
            init();
        }

        if (intervalCounter > 200) {
            console.error('Libs are not loaded properly. Please check your internet connection and try again.', getNotLoadedLibraries());
            clearInterval(initInterval);
        }
        intervalCounter++;
    }, 50);

    function init() {
        if (shouldShowPopup() === false) {
            console.log('Warning popup not showing, because ', getReasonToNotShowPopup());
            return;
        }

        const modalText = `Hortisketch is only accessible from your desktop or laptop computer. 
                After completing signup on your mobile device, login on your computer to enjoy Hortisketch.`;

        const infobox = new ModalInfobox();
        infobox.setBtnClickHandler(() => infobox.hide());
        infobox.setOnClose(() => {
            setUserHasBeenInformed();
        });
        infobox.setText(modalText);
        infobox.setBtnText('Continue');
        infobox.show();
    }

    function setUserHasBeenInformed() {
        localStorage.setItem('userHasBeenInformed', true);
    }

    function isUserInformed() {
        return localStorage.getItem('userHasBeenInformed') === 'true';
    }

    function isRightPage() {
        const location = (new GSApp.UrlChecker(document.location.href));

        return location.pathIncludes('/subscription/')
            || location.isPath('/checkout/');
    }

    function shouldShowPopup() {
        if (isUserInformed()) {
            setReasonToNotShowPopup('User has already been informed.');
            return false;
        }

        if (GSApp.NavigatorChecker.isMobileDevice() === false) {
            setReasonToNotShowPopup('Warning is only accessible from mobile devices.');
            return false;
        }

        if (isHortisketch() === false) {
            setReasonToNotShowPopup('Subscription product is not Hortisketch.');
            return false;
        }

        return true;
    }

    function setReasonToNotShowPopup(message) {
        return reasonToNotShowPopup = message;
    }

    function getReasonToNotShowPopup() {
        return reasonToNotShowPopup;
    }

    function isHortisketch() {
        if (window.lumina?.cart) {
            return lumina?.cart[0]['isHortisketch'] === true;
        }

        return false;
    }

    function shouldInit() {
        return getNotLoadedLibraries().length === 0;
    }

    function getNotLoadedLibraries()
    {
        let notLoadedLibraries = [];

        if (typeof window.ModalInfobox === 'undefined') {
            notLoadedLibraries.push('ModalInfobox');
        }

        if (typeof window.GSApp?.NavigatorChecker === 'undefined') {
            notLoadedLibraries.push('NavigatorChecker');
        }

        return notLoadedLibraries;
    }
})();
