;(function() {
    window.GSApp = window.GSApp || {};

    GSApp.Utils = GSApp.Utils ? GSApp.Utils : {};

    GSApp.Utils.ElementUtil = {
        getOffset: function(el) {
            let top = 0, left = 0;
            while (el !== null) {
                top += el.offsetTop;
                left += el.offsetLeft;
                el = el.offsetParent;
            }
            return {top, left};
        }
    }

    //call event that library is loaded
    document.dispatchEvent(new CustomEvent('lum:utils:elementutil', {detail: GSApp.Utils.ElementUtil}));
})();
