;document.addEventListener('DOMContentLoaded', function() {
    const initInterval = setInterval(function () {
        if (jQuery) {
            clearInterval(initInterval);
            init(jQuery);
        }
    }, 50);

    function init($){
        const $planSwitchBtns = $('.jsChangeHortisketchSubscribePlan');

        if ($planSwitchBtns.length === 0) {
            return;
        }

        const getPlanDetails = ($switchBtn) => $switchBtn.data('productDetails');
        const getPlanWrapperEl = ($switchBtn) => $switchBtn.closest('.shopSubscriptionOffer');
        const setMainPriceText = ($planEl, priceText) => $planEl.find('.month-price').html(priceText);

        $planSwitchBtns.click(function () {
            const $clickedBtn = $(this);
            const details = getPlanDetails($clickedBtn);
            const $planEl = getPlanWrapperEl($clickedBtn);

            $planSwitchBtns.removeClass('selected');
            $clickedBtn.addClass('selected');

            setMainPriceText($planEl, `$${details.price} <span>${details.period}</span>`);
            $planEl.find('.jsAddProductToCart').data('productSku', details.sku);

            const eventDetils = {
                '$clickedBtn': $clickedBtn,
                '$planEl': $planEl,
                'selectedPlanDetails': details,
                'setMainPriceText': setMainPriceText,

            };
            document.dispatchEvent(new CustomEvent('hortisketch-plan-switched', { detail: eventDetils }));
        });

        //trigger click on a selected btn, because some additional handlers can modify this plan
        const $defaultPlanBtn = $planSwitchBtns[0];
        let $planBtn = $planSwitchBtns.filter((index, el) => $(el).hasClass('selected')).first();
        if ($planBtn.length === 0) {
            $planBtn = $defaultPlanBtn;
        }
        $planBtn.click();
    }
});
