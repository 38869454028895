;(function(){
    const lumAppLoader = function(libPaths, maxTimeOnLoad){
        maxTimeOnLoad = maxTimeOnLoad ? maxTimeOnLoad : 20000;

        return new Promise(function (resolve, reject) {
            const paths = getObjectPaths(libPaths);


            let intervalTime = 10;
            let intervalCurrentTime = 0;
            const interval = setInterval(() => {
                intervalCurrentTime += intervalTime;

                try {
                    const checkResult = checkPathsExistence(paths, window);

                    const notLoadedLibs = getNotLoadedLibs(checkResult);

                    if (notLoadedLibs.length === 0) {
                        clearInterval(interval);
                        resolve();
                    }

                    if (intervalCurrentTime >= maxTimeOnLoad) {
                        clearInterval(interval);
                        let err = new Error('Not all librariries has been loaded till timeout.');
                        err.libsNotLoaded = notLoadedLibs;

                        reject(err);
                    }
                } catch(e) {
                    clearInterval(interval);
                    reject(e);
                }

            }, intervalTime);
        });
    }

    function getNotLoadedLibs(resultOfCheckPathExistance){
        let notLoadedLibs = [];

        for (let libIndex in resultOfCheckPathExistance) {
            if (resultOfCheckPathExistance.hasOwnProperty(libIndex)) {
                if (resultOfCheckPathExistance[libIndex].exists === false) {
                    notLoadedLibs.push(resultOfCheckPathExistance[libIndex].path);
                }
            }
        }

        return notLoadedLibs;
    }

    function getObjectPaths(obj, prefix = '') {
        let paths = [];
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let fullPath = prefix.length ? `${prefix}.${key}` : key;
                if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                    paths = paths.concat(getObjectPaths(obj[key], fullPath));
                } else {
                    if (Array.isArray(obj[key])) {
                        obj[key].forEach((item, index) => {
                            paths.push(`${fullPath}.${item}`);
                        });
                    } else {
                        paths.push(fullPath);
                    }
                }
            }
        }
        return paths;
    }

    function checkPathsExistence(paths, rootObject) {
        return paths.map(path => {
            return { path, exists: checkNestedObjectExistence(path, rootObject) };
        });
    }

    function checkNestedObjectExistence(path, obj) {
        const parts = path.split('.');
        let current = obj ? obj : parts[0];
        for (let part of parts) {
            if (typeof current[part] === 'undefined') {
                return false;
            }
            current = current[part];
        }

        return true;
    }

    window.lumAppLoader = lumAppLoader;

    document.dispatchEvent(new CustomEvent('lumAppLoader:init', {detail: lumAppLoader}));
})();
