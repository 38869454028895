/**
 * checkObjectKey - this function checks if a key exists in an object
 */
;(function() {
    'use strict';

    window.ObjectUtils = window.ObjectUtils || {};

    ObjectUtils.checkObjectKey = function(obj, key) {

        var keys = key.split(".");

        var current = obj;

        for (var i = 0; i < keys.length; i++) {
            var k = keys[i];

            if (current.hasOwnProperty(k)) {
                current = current[k];
            } else {
                return false;
            }
        }

        return true;
    }
})();
