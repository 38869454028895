;(function(factory) {
    // conditional factory logic should be placed here.
    if (typeof Lum == 'undefined' || typeof jQuery == 'undefined') {
        var lmnInterval = setInterval(function(){
            if (typeof Lum != 'undefined' && typeof jQuery != 'undefined') {
                clearInterval(lmnInterval);
                factory(jQuery);
            }
        }, 100);
    } else {
        factory(jQuery);
    }
})(function($) {
    var queuedCallback;
    /**
     * Loads jQuery Mask
     * 
     * @param  {Function} callback The callback to execute once the mask is loaded.
     * 
     * @return {Void}            
     */
    function includeRecaptcha(callback) {
        if (typeof $ == 'undefined') {
            return setTimeout(function() {
                includeRecaptcha(callback);
            }, 200);
        }
        var recaptchaURL = 'https://www.google.com/recaptcha/api.js';
        Lum.loadScriptAsync(recaptchaURL, callback);
    }
    /**
     * This method initialises parsley if and adds the custom validation
     * as well as the bindings.
     */
    var initRecaptcha = function () {
        initStandardRecaptcha();
        initInvisibleRecaptcha();
    };
    /**
     * This is the standart V3 recaptcha init.
     */
    var initStandardRecaptcha = function () {
        var container = $("<span class='errorContainer'></span>");
        var inp = $('<textarea style="display:none;" data-parsley-errors-container=".errorContainer" data-parsley-required="true" data-required-message="reCaptcha not passed!" name="recaptcha" value="" class="g-token" callback="callback" required></textarea>');
        container.insertAfter($(".g-recaptcha"));
        inp.insertBefore($(".g-recaptcha"));
        if (typeof Lum.Parsley != 'undefined') {
            Lum.Parsley.applyOn('.g-token', {
                errorsContainer: function(pEle) {
                    var $err = pEle.$element.siblings('.errorContainer');
                    return $err;
                }
            });
        }
    };
    /**
     * Invisible reCaptcha init
     */
    var initInvisibleRecaptcha = function () {
        if (typeof grecaptcha == 'undefined' || typeof grecaptcha.render == 'undefined') {
            return setTimeout(initInvisibleRecaptcha, 100);
        }
        var containers = $(".g-recaptcha-invisible");
        containers.each(function() {
            grecaptcha.render($(this).attr('id'),{
                "sitekey": $(this).data('sitekey'),
                // "badge": "inline",
                // "type": "image",
                "size": "invisible",
                "callback": $(this).data('callback')
            });
        });
    };
    var callback = function (token) {
        console.log('Processing it!');
        if (typeof queuedCallback == 'function') {
            queuedCallback(token);
            delete queuedCallback;
        }
        $(".g-token").val(token);
    };
    var execute = function (callback) {
        if (typeof grecaptcha == 'undefined' || typeof grecaptcha.execute == 'undefined') {
            return setTimeout(execute, 100);
        }
        if (typeof callback == 'function') {
            queuedCallback = callback;
        }
        console.log('Executing it!');
        grecaptcha.execute();
    };
    var reset = function (callback) {
        if (typeof grecaptcha == 'undefined' || typeof grecaptcha.reset == 'undefined') {
            return setTimeout(reset, 100);
        }
        console.log('Resetting it!');
        grecaptcha.reset();
    };
    /**
     * Keep the file versioned.
     * @return {String}
     */
    var version = function() {
        return '1.0.2';
    };
    window.Lum.Recaptcha = {
        onValidation: execute,
        reset: reset,
        callback: callback,
        version: version
    };
    window.lumRecaptchaCallback = callback;
    includeRecaptcha(initRecaptcha);
});