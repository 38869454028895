;(function () {
    const FloaterCookieName = 'floater';

    document.addEventListener('lum:utils:cookies', function (e) {
        const Cookies = e.detail;

        setTimeout(function () {
            Cookies.setCookie(
                FloaterCookieName,
                1
            );
        }, 10000);
    })
})();
